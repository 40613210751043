<template>
    <v-sheet v-bind="$attrs" class="v-dragdrop-field"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        @click="$refs.input.click()"
        :class="{ 'grey lighten-2': dragover }">

        <v-layout fill-height class="align-center justify-center">
            <v-flex shrink>
                <div class="text-center"><v-icon icon="mdi-cloud-upload" size="32"></v-icon></div>
                <div class="mt-2 body-2"><span>파일을 이쪽으로 끌어오거나, 클릭하여 파일첨부</span></div>
            </v-flex>
        </v-layout>
        <v-file-input v-model="file" ref="input" v-bind="$attrs" :multiple="multiple" class="d-none" @change="input"/>
    </v-sheet>
</template>

<script>
export default {
    props: {
        modelValue: { type: [Object, File, Array], default: null },
        multiple: { type: Boolean, default: false }
    },
    data(){
        return {
            file: this.$props.modelValue,

            dragover: false,
        }
    },
    methods: {
        input(files){
            this.$emit("change", files.target.files[0]);
            this.$emit("input", files.target.files[0]);
            this.$emit("update:model-value", files.target.files[0]);
        },
        onDrop(event){
            this.dragover = false;

            if(!this.multiple && event.dataTransfer.files.length > 1){
                alert("한개의 파일만 업로드 가능합니다");
            }
            else{
                this.$emit("change", this.multiple ? event.dataTransfer.files : event.dataTransfer.files?.[0]);
                this.$emit("input", this.multiple ? event.dataTransfer.files : event.dataTransfer.files?.[0]);
            }
        }
    },
    watch: {
        modelValue(){ this.file = this.modelValue; }
    }
}
</script>
