<template>
    <v-responsive width="100%" max-width="1024" class="overflow-visible mx-auto">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="giftcard._id">상품상세</span>
                <span v-else>상품등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mx-1 mt-4">
            <v-card-title class="d-flex pb-2 align-center">
                <span class="subtitle-2"><b>카테고리</b></span>
                <v-spacer/>
                <v-btn color="primary" @click="giftcard._categories.push(null)"><v-icon small class="mr-2">mdi-plus</v-icon> 카테고리 추가</v-btn>
            </v-card-title>
            <v-card-text>
                <v-select v-model="giftcard._category" :items="categories" item-text="name" item-value="_id" placeholder="카테고리" />
            </v-card-text>
        </v-card>
        
        <v-card class="mx-1 mt-4">
            <v-card-title class="d-flex pb-2 align-center">
                <span class="subtitle-2"><b>브랜드</b></span>
            </v-card-title>
            <v-card-text>
                <v-layout>
                    <v-select v-model="giftcard._brand" :items="brands" item-text="name" item-value="_id" label="브랜드" />
                </v-layout>
            </v-card-text>
        </v-card>

        <v-card class="mx-1 mt-4">
            <v-card-title>
                <span class="subtitle-2"><b>기본정보</b></span>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="giftcard.name" label="상품권이름" persistent-placeholder hide-details="" />
                <v-text-field v-model="giftcard.code" label="상품권코드" persistent-placeholder hide-details="" class="mt-4" />
                <v-text-field v-model="giftcard.no" label="진열순서" persistent-placeholder hide-details="" class="mt-4" />
                <v-text-field v-show="false" v-model="giftcard.faceamt" label="액면가" persistent-placeholder hide-details="" class="mt-4" />
                <v-text-field v-model="giftcard.salePrice" label="판매가" persistent-placeholder hide-details="" class="mt-4" />
                <v-text-field v-show="false" v-model="giftcard.stock" label="재고" persistent-placeholder hide-details="" class="mt-4" />
                <v-text-field v-model="giftcard.publisher" label="발행업체" persistent-placeholder hide-details="" class="mt-4" />
                <v-text-field v-model="giftcard.center" label="고객센터" persistent-placeholder hide-details="" class="mt-4" />
            </v-card-text>
        </v-card>

        <v-card class="mx-1 mt-4">
            <v-card-title class="subtitle-2 font-weight-bold">썸네일</v-card-title>
            <v-card-text>
                <v-layout no-gutters align-center>
                    <v-img v-if="thumb" :src="createObjectURL(thumb)" max-width="96" height="auto" contain></v-img>
                    <v-img v-else-if="giftcard.thumb" :src="giftcard.thumb?.src" max-width="96" height="auto" contain></v-img>
                    <v-sheet v-else width="96" height="96" color="grey-lighten-2" class="d-flex justify-center align-center "><v-icon>mdi-image</v-icon></v-sheet>
                    <v-file-input :placeholder="giftcard.thumb?.name" @change="(file) => thumb = file" />
                </v-layout>
            </v-card-text>
        </v-card>

        <v-card class="mx-1 mt-3">
            <v-card-title class="d-flex align-center">
                <span class="subtitle-2"><b>이미지</b></span>
                <v-spacer/>
                <span>
                    <v-btn color="primary" small @click="giftcard.images.push(null)"><v-icon small class="mr-2">mdi-plus</v-icon>이미지추가</v-btn>
                </span>
            </v-card-title>
            <v-card-text>
                <v-layout v-for="image, index in giftcard.images" :key="index" no-gutters align-center class="mb-4">
                    <v-img v-if="images[index]" :src="createObjectURL(images[index])" max-width="96" height="auto" contain></v-img>
                    <v-img v-else-if="giftcard.images[index]" :src="giftcard.images[index].src" max-width="96" height="auto" contain></v-img>
                    <v-sheet v-else width="96" height="96" color="grey-lighten-2" class="d-flex justify-center align-center"><v-icon>mdi-image</v-icon></v-sheet>
                    <v-file-input :placeholder="giftcard.images[index]?.name" @change="(file) => $set(images, index, file)" />
                </v-layout>
            </v-card-text>
        </v-card>
        
        <!-- <v-card class="mx-1 mt-4">
            <v-card-title class="align-center">
                <span class="subtitle-2"><b>베스트</b></span>
                <v-spacer/>
                <v-switch v-model="giftcard.isBest" hide-details class="mt-0"/>
            </v-card-title>
        </v-card> -->

        <!-- <v-card class="mx-1 mt-4">
            <v-card-title class="d-flex align-center">
                <span class="subtitle-2"><b>신용카드 결제 수수료</b></span>
                <v-spacer/>
                <span>
                    <v-text-field v-model="giftcard.commission.creditcard" dense hide-details outlined suffix="%" class="shrink" style="width: 120px" />
                </span>
            </v-card-title>
            <v-divider/>
            <v-card-title class="d-flex align-center">
                <span class="subtitle-2"><b>절사(절삭) 단위</b></span>
                <v-spacer/>
                <span>
                    <v-select v-model="giftcard.commission.cutUnit" :items="[1, 10, 100, 1000]" dense hide-details outlined suffix="원" class="shrink" style="width: 120px" />
                </span>
            </v-card-title>
            <v-divider />
            <v-card-title class="d-flex align-center">
                <span class="subtitle-2"><b>할인율 표기</b></span>
                <v-spacer/>
                <span>
                    <v-text-field v-model="giftcard.discount" dense hide-details outlined suffix="%" class="shrink" style="width: 120px" />
                </span>
            </v-card-title>
            <v-divider />
        </v-card> -->

        <v-card class="mx-1 mt-4">
            <v-card-title class="d-flex align-center">
                <span class="subtitle-2"><b>품절여부 (판매 / 품절)</b></span>
                <v-spacer/>
                <span>
                    <v-switch v-model="giftcard.soldout" color="red" hide-details class="mt-0"/>
                </span>
            </v-card-title>
            <v-divider/>
            <v-card-title class="d-flex align-center">
                <span class="subtitle-2"><b>진열여부 (진열안함 / 진열)</b></span>
                <v-spacer/>
                <span>
                    <v-switch v-model="giftcard.onDisplay" color="blue" hide-details class="mt-0"/>
                </span>
            </v-card-title>
            <v-divider/>
            <v-card-title class="d-flex align-center">
                <span class="subtitle-2"><b>추천여부 (추천안함 / 추천)</b></span>
                <v-spacer/>
                <span>
                    <v-switch v-model="giftcard.isRecommend" color="blue" hide-details class="mt-0"/>
                </span>
            </v-card-title>
        </v-card>

        <giftcard-options v-if="ready" v-model="giftcard.options" :giftcard="giftcard" class="mt-4" @update:thumb="file => uploadThumb(file)" @update:images="file => uploadImage(file, 0)" />

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">상세정보</v-card-title>
            <v-card-text>
                <naver-smarteditor v-if="ready" v-model="giftcard.content" />
            </v-card-text>
        </v-card>
        
        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">유의사항</v-card-title>
            <v-card-text>
                <naver-smarteditor v-if="ready" v-model="giftcard.warning" />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" :disabled="!ready" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </v-responsive>
</template>

<script>
import api from "@/api";

import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import CategorySelect from "@/components/console/gshop2/categories/category-select.vue";
import GiftcardOptions from "@/components/console/gshop2/giftcards/giftcard-options.vue";
import ExcelGiftcards from "@/components/console/gshop2/giftcards/excel-giftcards.vue";

export default {
    components: {
        CategorySelect,
        NaverSmarteditor,
        ExcelGiftcards,
        GiftcardOptions,
    },
    data(){
        return {
            ready: false,

            giftcard: {
                _id: this.$route.params._giftcard,
                _category: null,

                no: 0,
                name: null,
                code: null,
                type: "giftcard",

                costPrice: 0,
                salePrice: 0,
                stock: 0,
                soldout: false,

                commission: {
                    creditcard: 0,
                    mobile: 0,
                    cutUnit: 1,
                },

                paymentMethod: "creditcard",

                thumb: null,
                images: new Array(1),
                
                onSale: true,
                onDisplay: true,

                options: [],
                pins: [],
            },

            thumb: null,
            images: new Array(1).fill(null),

            optionalEnabled: false,
            additionalEnabled: false,

            categories: [],
            brands: [],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            // 카테고리 조회
            let { categories } = await api.console.gshop2.categories.gets({ params: { depth: 1 }});
            this.categories = categories;

            let { brands } = await api.console.gshop2.brands.gets();
            this.brands = brands;

            let { summary } = await api.console.gshop2.giftcards.gets({ params: { type: "giftcard" } });
            this.giftcard.no = summary.totalCount + 1;

            if(this.$route.params._id){
                let { giftcard } = await api.console.gshop2.giftcards.get({ _id: this.$route.params._id });
                
                this.giftcard = giftcard;
                this.giftcard.pins = [];
                this.images = new Array(giftcard.images.length);
            }

            this.ready = true;
        },

        validate(){
            let giftcard = this.giftcard;
            try{
                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        async save(){
            let { giftcard } = !this.giftcard._id
            ? await api.console.gshop2.giftcards.post(this.giftcard)
            : await api.console.gshop2.giftcards.put(this.giftcard);
            
            // 상품권 옵션 등록
            for(let option of this.giftcard.options?.filter?.(({ name, isDeleted }) => !!name && !isDeleted) || []){
                option._parent = giftcard._id;
                option.soldout = giftcard.soldout;

                let response = option._id
                ? await api.console.gshop2.giftcards.put({ ...option, thumb: giftcard.thumb }) 
                : await api.console.gshop2.giftcards.post({ ...option, thumb: giftcard.thumb });

                option._id = response.giftcard._id;
                option.faceamt = response.giftcard.faceamt;
            }

            // // 엑셀 핀 업로드
            // for(let pin of this.giftcard.pins.filter(({ status, isDeleted }) => status == "미발행" && !isDeleted)){
            //     let option = this.giftcard.options.find(({ name, faceamt, isDeleted }) => (name == pin.name && faceamt == pin.faceamt) && !isDeleted);

            //     pin._id
            //     ? await api.console.gshop.pins.put({ ...pin, _giftcard: giftcard?._id, _option: option?._id })
            //     : await api.console.gshop.pins.post({ ...pin, _giftcard: giftcard?._id, _option: option?._id });
            // }

            // // 엑셀 핀 삭제
            // for(let pin of this.giftcard.pins.filter(({ isDeleted }) => !!isDeleted)){
            //     await api.console.gshop.pins.delete(pin);
            // }

            // 상품권 옵션 삭제
            for(let option of this.giftcard.options?.filter?.(({ isDeleted }) => !!isDeleted) || []){
                await api.console.gshop2.giftcards.delete({ _id: option._id });
            }

            if(this.thumb){
                giftcard.thumb = await api.console.gshop2.giftcards.thumb.post(giftcard._id, this.thumb)
            }
            for(let index in this.images){
                let image = this.images[index];
                if(image){
                    giftcard.images[index] = await api.console.gshop2.giftcards.images.post(giftcard._id, image, this.images.indexOf(image));
                }
            }

            await api.console.gshop2.giftcards.put(giftcard);

            alert("저장되었습니다");
            this.$router.go(-1);
        },

        createObjectURL(url){
            try{
                return URL.createObjectURL(url)
            }
            catch(error){}
            return null;
        }
    },
    computed: {
        siteInfo() {
            return this.$store?.state?.siteInfo;
        },
    }
}
</script>