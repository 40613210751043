<template>
    <v-responsive width="100%" max-width="1024" class="overflow-visible mx-auto">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">카테고리 관리</v-col>
        </v-row>
        
        <v-row>
            <v-col cols="12" md="6">
                <v-card class="mx-1">
                    <v-toolbar color="secondary" dense>
                        <v-toolbar-title class="white--text subtitle-2">카테고리</v-toolbar-title>

                        <v-spacer></v-spacer>
                        <v-btn color="accent" fab dark small absolute bottom right @click="$refs.categoryDialog.open()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-list v-if="categories.length" >
                            <category-list :categories="categories" :category="category" @update:category="category => $data.category = category" />
                        </v-list>
                        <span v-else>등록된 카테고리가 없습니다.<br>카테고리를 등록해주세요</span>
                    </v-card-text>

                </v-card>
            </v-col>
            
            <v-col cols="12" md="6">
                <v-card :disabled="!category?._id" class="mx-1">
                    <v-card-title class="subtitle-1">카테고리 기본정보</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="category._id" label="_id" persistent-placeholder hide-details disabled></v-text-field>
                        <v-text-field v-model="category.name" label="카테고리 이름" persistent-placeholder hide-details class="mt-4"></v-text-field>
                        <v-text-field v-model="category.code" label="카테고리 코드(영문)" persistent-placeholder hide-details class="mt-4"></v-text-field>
                    </v-card-text>
                    <v-card-text>
                        <div class="text-caption">카테고리 썸네일</div>
                        <v-layout no-gutters align-center>
                            <v-img v-if="thumb" :src="createObjectURL(thumb)" max-width="96" height="auto" contain></v-img>
                            <v-img v-else-if="category.thumb" :src="category.thumb?.src" max-width="96" height="auto" contain></v-img>
                            <v-sheet v-else width="96" height="96" color="grey-lighten-2" class="d-flex justify-center align-center "><v-icon>mdi-image</v-icon></v-sheet>
                            <v-file-input :placeholder="category.thumb?.name" @update:model-value="([file]) => thumb = file" />
                        </v-layout>
                    </v-card-text>
                </v-card>

                <v-row class="mt-0 px-1">
                    <v-col md="auto">
                        <v-btn color="grey darken-1" class="white--text" @click="remove">삭제</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col md="auto">
                        <v-btn color="primary" @click="save">저장</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <category-dialog ref="categoryDialog" @save="search"></category-dialog>
    </v-responsive>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import CategoryDialog from "@/components/console/gshop2/categories/category-dialog.vue";
import CategoryList from '@/components/console/gshop2/categories/category-list.vue';

export default {
    components: {
        NaverSmarteditor,
        CategoryDialog,
        CategoryList
    },
    data(){
        return {
            ready: false,

            category: {},
            categories: [
            ],

            thumb: null
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            this.search();
            if(this.category._id){
                let { category } = await api.console.gshop2.categories.get(this.category);
                this.category = category;
            }
            this.ready = true;
        },
        async search(){
            let { categories } = await api.console.gshop2.categories.gets({
                headers: { limit: 0 },
                params: { depth: 1 }
            });

            this.categories = categories;
            this.$refs.categoryDialog.init();
        },
        async save(){
            let { category } = this.category._id 
            ? await api.console.gshop2.categories.put(this.category) 
            : await api.console.gshop2.categories.post();

            if(this.thumb){
                await api.console.gshop2.categories.thumb.post(category._id, this.thumb);
                this.thumb = null;
            }

            alert("저장되었습니다");
        },
        async remove(){
            await api.console.gshop2.categories.delete(this.category);
            alert("삭제되었습니다");
            this.search();
        },
        async sort(categories = this.categories, depth = 1, _parents = []){
            for(let category of categories){
                await api.console.gshop.categories.put({
                    ...category,
                    depth,
                    _children: category.children.map(child => child._id),
                    _parents
                });
                if(category.children) this.sort(category.children, depth + 1, [...category._parents, category._id ]);
            }
        },
        createObjectURL(url){
            try{
                return URL.createObjectURL(url)
            }
            catch(error){}
            return null;
        }
    },
}
</script>