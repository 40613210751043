<template>
    <v-layout>
        <v-flex v-for="(depth, index) in max" :key="index" grow>
            <v-select :items="items(depth)" item-title="name" item-value="_id" :model-value="values[depth-1]" @update:model-value="input" :label="`${depth}차 카테고리`"  :persistent-placeholder="$props['persistent-placeholder']" :dense="dense" :hide-details="hideDetails" class="mt-0"></v-select>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    props: {
        dense: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false },
        persistentPlaceholder: { type: Boolean, default: false },
        modelValue: { default: null },
        categories: { type: Array, default() { return []; } },
        returnObject: { type: Boolean, default: false },
    },
    data() {
        return {
            category: null,
        };
    },
    created(){
        this.category = this.find(this.returnObject ? this.modelValue?._id : this.modelValue);
    },
    methods: {
        input(_category){
            this.category = this.find(_category);
            this.$emit('update:model-value', this.returnObject ? this.category : _category);
            this.$emit('input', this.returnObject ? this.category : _category);
        },


        find(_category, categories = this.categories){
            for(var category of categories){
                if(category._id == _category) return category;
                if(category.children?.length){
                    let result = this.find(_category, category.children);
                    if(result) return result;
                }
            }

            return null;
        },

        items(depth){
            if(depth == 1) return [{ name: ":: 전체 ::", _id: null }, ...this.categories];

            let category = this.find(this.values[depth - 2]);
            return [{ name: ":: 전체 ::", _id: category?._id || null }, ...(depth - 1 <= category?.depth ? category?.children : [])];
        },

        deep(categories = this.categories, depth = 1){
            let max = depth;
            for(var category of categories){
                if(category?.children?.length){
                    let result = this.deep(category.children, depth + 1);
                    if(max < result) max = result;
                }
            }
            return max;
        },
    },
    computed: {
        max(){
            return this.deep();
        },

        values(){
            let values = new Array(this.max).fill(null);
            if(this.category){
                values.fill(this.category._id);
                this.category._parents.forEach((value, index) => values[index] = value);
            }
            return values;
        }
    },
    watch: {
        modelValue(){
            this.category = this.find(this.returnObject ? this.modelValue?._id : this.modelValue);
        }
    }
}
</script>
