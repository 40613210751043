<template>
    <edit-view />
</template>

<script>
import EditView from "./[_id]/index.vue";

export default {
    components: {
        EditView
    },
}
</script>