var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "560"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.show ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v("신규 카테고리 등록")]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "카테고리 이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.name,
      callback: function ($$v) {
        _vm.$set(_vm.category, "name", $$v);
      },
      expression: "category.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "카테고리 코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.code,
      callback: function ($$v) {
        _vm.$set(_vm.category, "code", $$v);
      },
      expression: "category.code"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("등록")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }