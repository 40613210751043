var render = function render(){
  var _vm$brand$thumb, _vm$brand$thumb2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-responsive', {
    staticClass: "overflow-visible mx-auto",
    attrs: {
      "max-width": "720"
    }
  }, [_c('v-row', {
    staticClass: "mt-0 mt-md-4 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.brand._id ? _c('span', [_vm._v("브랜드상세")]) : _c('span', [_vm._v("브랜드등록")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', [_c('v-select', {
    attrs: {
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "_id",
      "label": "커테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.brand._category,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "_category", $$v);
      },
      expression: "brand._category"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "브랜드 명칭",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.brand.name,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "name", $$v);
      },
      expression: "brand.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "브랜드 순서",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.brand.no,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "no", $$v);
      },
      expression: "brand.no"
    }
  })], 1), _c('v-divider'), _c('v-card-text', [_c('v-layout', {
    attrs: {
      "no-gutters": "",
      "align-center": ""
    }
  }, [_vm.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.createObjectURL(_vm.thumb),
      "max-width": "56",
      "height": "auto",
      "contain": ""
    }
  }) : _vm.brand.thumb ? _c('v-img', {
    attrs: {
      "src": (_vm$brand$thumb = _vm.brand.thumb) === null || _vm$brand$thumb === void 0 ? void 0 : _vm$brand$thumb.src,
      "max-width": "56",
      "height": "auto",
      "contain": ""
    }
  }) : _c('v-sheet', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "width": "56",
      "height": "56",
      "color": "grey-lighten-2"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-file-input', {
    attrs: {
      "placeholder": (_vm$brand$thumb2 = _vm.brand.thumb) === null || _vm$brand$thumb2 === void 0 ? void 0 : _vm$brand$thumb2.name,
      "label": "썸네일",
      "persistent-placeholder": ""
    },
    on: {
      "change": function (file) {
        return _vm.thumb = file;
      }
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("진열여부 (진열안함 / 진열)")])]), _c('v-spacer'), _c('span', [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "color": "blue",
      "hide-details": ""
    },
    model: {
      value: _vm.brand.onDisplay,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "onDisplay", $$v);
      },
      expression: "brand.onDisplay"
    }
  })], 1)], 1), _c('v-divider')], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1), _c('daum-postcode', {
    ref: "daumPostcode",
    on: {
      "input": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        _vm.brand.postcode = postcode;
        _vm.brand.address1 = address;
      }
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }