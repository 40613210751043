<template>
    <v-responsive width="100%" max-width="1280" class="overflow-visible mx-auto">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline text-start">상품권 관리</v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch"> <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터 </v-btn>
            </v-col>
        </v-row>

        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-2 mx-1">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter._brand" :items="brands" item-title="name" item-value="_id" label="브랜드" placeholder=":: 전체 ::" clearable persistent-placeholder hide-details />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.isBadge" :items="badges" item-title="title" item-value="value" label="뱃지여부" placeholder=":: 전체 ::" clearable persistent-placeholder hide-details />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.isRecommend" :items="recommends" item-title="title" item-value="value" label="추천여부" placeholder=":: 전체 ::" clearable persistent-placeholder hide-details />
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.searchKey" :items="searchKeys" item-title="text" item-value="value" label="검색조건" placeholder=":: 전체 ::" clearable persistent-placeholder hide-details @input="filter.searchValue = null" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="filter.searchValue"
                                label="검색어"
                                placeholder=" "
                                hide-details
                                :disabled="!filter.searchKey"
                                @keydown.enter="
                                    page = 1;
                                    search(true);
                                "
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        rounded
                        text
                        @click="
                            $router.push({ query: {} });
                            $router.go(0);
                        "
                    >
                        <v-icon small class="mt-1 mr-2">mdi-refresh</v-icon>초기화
                    </v-btn>
                    <v-btn
                        color="primary"
                        rounded
                        text
                        @click="
                            page = 1;
                            search(true);
                        "
                    >
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>

        <v-row class="mt-4 px-1" align="end">
            <v-col cols="auto" class="subtitle-2">검색수 : {{ summary.totalCount.format() }}</v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn v-bind="{ loading }" color="white" elevation="1" class="green--text px-2 ml-1" @click="excel"> <v-icon left size="16">mdi-microsoft-excel</v-icon><span>엑셀다운로드</span> </v-btn>
                <v-btn color="white" elevation="1" class="green--text px-2 ml-1" href="/res/console/eq/all_product.txt" target="_blank"> <v-icon left size="16">mdi-text-box</v-icon><span>네이버 EP 보기</span> </v-btn>
            </v-col>
        </v-row>

        <v-simple-table class="elevation-1 mt-4">
            <thead>
                <tr>
                    <th class="text-center" style="width: 100px">진열순서</th>
                    <th class="text-center">상품코드</th>
                    <th class="text-center">썸네일</th>
                    <th>상품권 이름</th>
                    <th class="text-center" style="width: 100px">
                        진열해제<br />
                        / 진열
                    </th>
                    <th class="text-center" style="width: 100px">
                        뱃지해제<br />
                        / 뱃지
                    </th>
                    <th class="text-center" style="width: 100px">
                        추천해제<br />
                        / 추천
                    </th>
                    <th class="text-center" style="width: 140px">등록일자</th>
                    <th class="text-center" style="width: 100px">actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(giftcard, index) in giftcards" :key="index">
                    <td class="text-center">{{ giftcard.no }}</td>
                    <td class="text-center">{{ giftcard.code }}</td>
                    <td class="text-center"><v-img :src="giftcard?.thumb?.src" max-width="96" height="96" contain class="ma-auto" /></td>
                    <td>{{ giftcard.name }}</td>
                    <td class="text-center">
                        <v-switch v-model="giftcard.onDisplay" density="compact" color="blue" hide-details class="d-inline-block" @change="update({ _id: giftcard._id, type: giftcard.type, onDisplay: giftcard.onDisplay })" />
                    </td>
                    <td class="text-center">
                        <v-switch v-model="giftcard.isBadge" density="compact" color="blue" hide-details class="d-inline-block" @change="update({ _id: giftcard._id, type: giftcard.type, isBadge: giftcard.isBadge })" />
                    </td>
                    <td class="text-center">
                        <v-switch v-model="giftcard.isRecommend" density="compact" color="blue" hide-details class="d-inline-block" @change="update({ _id: giftcard._id, type: giftcard.type, isRecommend: giftcard.isRecommend })" />
                    </td>
                    <td class="text-center">{{ $dayjs(giftcard.createdAt).format("YYYY-MM-DD") }}</td>
                    <td class="text-center">
                        <v-icon size="20" class="mx-1" @click="edit(giftcard)">mdi-pencil</v-icon>
                        <v-icon size="20" class="mx-1" @click="remove(giftcard)">mdi-delete</v-icon>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)" />

        <v-btn v-bind="{ loading }" color="accent" size="large" icon="mdi-pencil" elevation="1" fab fixed right bottom class="me-4" @click="create()"><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn v-tooltip:top.eager="'Tdfadfa'" color="accent" size="large" icon="mdi-pencil" elevation="1" fab fixed right bottom class="my-16 me-4" @click="saveEP()">
            <v-tooltip activator="parent" location="end">네이버 EP 저장</v-tooltip>
            <v-icon>mdi-content-save</v-icon>
        </v-btn>
    </v-responsive>
</template>

<script>
import api from "@/api";
import XLSX from "@/plugins/xlsx";

export default {
    data() {
        return {
            showSearch: true,
            loading: false,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,

                type: "giftcard",
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            giftcards: [],

            searchKeys: [
                { text: "상품권 이름", value: "name" },
                { text: "상품코드", value: "code" },
            ],

            brands: [],
            badges: [
                { title: "뱃지", value: true },
                { title: "뱃지해제", value: false },
            ],
            recommends: [
                { title: "추천", value: true },
                { title: "추천안함", value: false },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { brands } = await api.console.gshop2.brands.gets({ headers: { limit: 0 } });
            this.brands = brands;

            this.search(false);
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
            }

            let { summary, giftcards } = await api.console.gshop2.giftcards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.giftcards = giftcards;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        async saveEP(){
            if (this.loading) return;
            else this.loading = true;
            try {
                await api.plugins.naver.shopping.post({});
                alert("정상적으로 네이버 EP를 저장하였습니다.");
            } catch(error) {
                console.error(error);
                alert(error.repsonse ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        create() {
            this.$router.push(`/dhbizadm/gshop2/giftcards/create`);
        },
        edit(giftcard) {
            this.$router.push(`/dhbizadm/gshop2/giftcards/${giftcard._id}`);
        },
        async update(giftcard) {
            await api.console.gshop2.giftcards.put(giftcard);
        },
        async remove(giftcard) {
            if (confirm("삭제하시겠습니까?")) {
                await api.console.gshop2.giftcards.delete(giftcard);
                alert("삭제되었습니다");
                this.search(false);
            }
        },
        async excel() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { giftcards } = await api.console.gshop2.giftcards.gets({ headers: { limit: 0 }, params: this.filter });
                let rows = [];
                giftcards.forEach((giftcard) => {
                    rows.push({
                        진열순서: giftcard.no,
                        상품코드: giftcard.code,
                        상품이름: giftcard.name,
                        할인율: giftcard.discount,
                        진열여부: giftcard.onDisplay ? "진열" : "미진열",
                        등록일자: this.$dayjs(giftcard.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                    });
                    if (giftcard.options.length !== 0) {
                        giftcard.options.forEach((option) => {
                            rows.push({
                                진열순서: "",
                                상품코드: option.code,
                                상품이름: option.name,
                                할인율: "",
                                진열여부: option.onDisplay ? "진열" : "미진열",
                                등록일자: this.$dayjs(option.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                            });
                        });
                    }
                });

                let workbook = new XLSX.utils.book_new();
                let worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "상품목록");
                XLSX.writeFile(workbook, `상품권 목록(${this.$dayjs().format("YYYYMMDD_HHmmss")}).xlsx`);
            } catch (error) {
                console.error(error);
                alert(error.repsonse ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>