<template>
    <v-dialog v-model="show" max-width="1200">
        <v-card>
            <v-card-title class="text-subtitle-1">핀 발행 내역</v-card-title>
            <v-divider/>
            <v-simple-table density="compact">
                <thead>
                    <tr>
                        <th>상품권 이름</th>
                        <th>핀 번호</th>
                        <th>액면가</th>
                        <th>자동충전여부</th>
                        <th>핀상태</th>
                        <th>등록일자</th>
                        <th>발행일자</th>
                        <th>취소일자</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="pin, index in pins">
                        <tr :key="index">
                            <td>{{ pin.name }}</td>
                            <td>{{ pin.pno }}</td>
                            <td>{{ pin.faceamt.format() }}</td>
                            <td>{{ pin.isAutoCharged ? "자동충전" : "-" }}</td>
                            <td>{{ pin.status }}</td>
                            <td>{{ $dayjs(pin.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</td>
                            <td>{{ pin.issuedAt ? $dayjs(pin.issuedAt).format("YYYY-MM-DD HH:mm:ss") : "" }}</td>
                            <td>{{ pin.canceledAt ? $dayjs(pin.canceledAt).format("YYYY-MM-DD HH:mm:ss") : "" }}</td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" variant="text" elevation="0" @click="close">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            show: false,

            pins: [],
            pinsHeaders: [
                { text: "상품권 이름", value: "name", width: 200 },
                { text: "핀 번호", value: "pno", width: "200" },
                { text: "액면가", value: "faceamt", width: "140" },
                { text: "자동충전여부", value: "isAutoCharged", width: "140" },
                { text: "등록일자", value: "createdAt", width: 180 },
                { text: "발행일자", value: "issuedAt", width: 180 },
                { text: "취소일자", value: "canceledAt", width: 180 },
            ]
        }
    },
    methods: {
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        }
    }
}
</script>