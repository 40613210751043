<template>
<v-responsive class="overflow-visible">
    <v-card class="mx-1">
        <v-card-title>
            <v-layout align-center>
                <span class="subtitle-2"><b>상품권 옵션 목록</b></span>
                <v-spacer/>
                <v-btn v-if="(siteInfo.pinBtn) && siteInfo.nanuriGiftcardEnabled" color="secondary" small height="36" @click="add({ platform: `nanuri-giftcard` })" class="mr-2"><v-icon left>mdi-plus</v-icon>나누리 대행 옵션 추가</v-btn>
                <v-btn color="primary" small height="36" @click="add({ platform: 'nanuri' })" class="mr-2"><v-icon left>mdi-plus</v-icon>나누리핀 옵션 추가</v-btn>
            </v-layout>
        </v-card-title>
        <v-divider/>

        <v-simple-table>
            <thead>
                <tr>
                    <th>옵션이름</th>
                    <th width="140">플랫폼</th>
                    <th width="14%">코드(api)</th>
                    <th width="14%">액면가</th>
                    <th width="14%">판매가</th>
                    <th width="80">진열여부</th>
                    <th width="80">actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="option, index in options.filter(option => !option.isDeleted)" :key="index">
                    <td>
                        <v-text-field v-model="option.name" density="compact" hide-details variant="outlined" :placeholder="placeholders[index]?.name || ''"></v-text-field>
                    </td>
                    <td>
                        <v-select v-model="option.platform" :items="platformItems" item-title="text" item-value="value" variant="outlined" hide-details density="compact" />
                        <!-- <v-select v-model="option.platform" :items="[`excel`, `nanuri`, `nanuri-api`, `nanuri-excel`, `nanuri-giftcard`, `cultureland`]" variant="outlined" hide-details density="compact" /> -->
                    </td>
                    <td>
                        <v-text-field v-model="option.code" density="compact" hide-details variant="outlined" :placeholder="placeholders[index]?.code || ''"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="option.faceamt" density="compact" hide-details variant="outlined" suffix="원" :placeholder="placeholders[index]?.faceamt || ''"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="option.salePrice" density="compact" hide-details variant="outlined" suffix="원" :placeholder="placeholders[index]?.salePrice || ''"></v-text-field>
                    </td>
                    <td>
                        <v-switch v-model="option.onDisplay" color="blue" hide-details class="mt-0"/>
                    </td>
                    <td>
                        <v-btn color="white" class="px-1 mr-1 primary--text" width="36" height="36" style="min-width: auto" @click="remove(options, option, index)"><v-icon>mdi-minus</v-icon></v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>

    <v-card class="mx-1 mt-4" :disabled="loading">
        <v-card-title>
            <v-layout align-center>
                <span class="subtitle-2"><b>핀 누적 집계</b></span>
                <v-spacer/>
            </v-layout>
        </v-card-title>

        <v-divider />

        <v-simple-table dense>
            <tbody class="text-end">
                <tr>
                    <th class="text-end">전체</th>
                    <th class="text-end">미발행</th>
                    <th class="text-end">발행완료</th>
                    <th class="text-end">취소완료</th>
                </tr>
                <tr>
                    <td>{{ summaries.reduce((totalCount, summary) => totalCount + +summary.totalCount, 0)?.format?.() }}건</td>
                    <td>{{ summaries.reduce((totalCount, summary) => totalCount + (summary.status == "미발행" ? summary.totalCount : 0), 0)?.format?.() }}건</td>
                    <td>{{ summaries.reduce((totalCount, summary) => totalCount + (summary.status == "발행완료" ? summary.totalCount : 0), 0)?.format?.() }}건</td>
                    <td>{{ summaries.reduce((totalCount, summary) => totalCount + (summary.status == "취소완료" ? summary.totalCount : 0), 0)?.format?.() }}건</td>
                </tr>
                <tr>
                    <td>{{ summaries.reduce((totalAmount, summary) => totalAmount + summary.totalCount * summary.faceamt, 0)?.format?.() }}원</td>
                    <td>{{ summaries.reduce((totalAmount, summary) => totalAmount + (summary.status == "미발행" ? summary.totalCount * summary.faceamt : 0), 0)?.format?.() }}원</td>
                    <td>{{ summaries.reduce((totalAmount, summary) => totalAmount + (summary.status == "발행완료" ? summary.totalCount * summary.faceamt : 0), 0)?.format?.() }}원</td>
                    <td>{{ summaries.reduce((totalAmount, summary) => totalAmount + (summary.status == "취소완료" ? summary.totalCount * summary.faceamt : 0), 0)?.format?.() }}원</td>
                </tr>
                <tr></tr>
            </tbody>
        </v-simple-table>
    </v-card>

    <v-card class="mx-1 mt-4" :disabled="loading">
        <v-card-title>
            <v-layout align-center>
                <span class="subtitle-2"><b>핀 목록</b></span>
                <v-spacer/>
            </v-layout>
        </v-card-title>

        <v-divider/>

        <template v-if="!loading">
            <v-tabs v-model="tabIndex" height="56">
                <v-tab elevation="0">미발행({{ idle?.totalCount?.format?.() || 0 }})</v-tab>
                <v-tab elevation="0">발행({{ issued?.totalCount?.format?.() || 0 }})</v-tab>
                <v-tab elevation="0">취소({{ canceled?.totalCount?.format?.() || 0 }})</v-tab>
                <v-spacer/>
                <div class="d-flex mt-2 pr-6">
                    <v-date-month v-if="0 < tabIndex" v-model="filter.date" :label="tabIndex == 1 ? '발행일자' : '취소일자'" />
                    <v-select v-model="filter.name" label="상품권 이름(필터)" item-title="text" :items="[{ text: ':: 전체 ::', value: null }, ...options.filter(option => !!option.name).map(option => ({ text: option.name, value: option.name }))]" persistent-placeholder dense outlined hide-details class="ml-2" />
                </div>
            </v-tabs>

            <v-divider/>

            <v-window v-model="tabIndex" touchless>
                <v-window-item>
                    <giftcard-pins :giftcard="giftcard" :filter="{ status: `미발행`, name: filter.name }" @search="summary => idle = summary" />
                </v-window-item>
                <v-window-item>
                    <giftcard-pins :giftcard="giftcard" :filter="{ status: `발행완료`, name: filter.name, issuedAt: filter.date }" @search="summary => issued = summary" />
                </v-window-item>
                <v-window-item>
                    <giftcard-pins :giftcard="giftcard" :filter="{ status: `취소완료`, name: filter.name, canceledAt: filter.date }" @search="summary => canceled = summary" />
                </v-window-item>
            </v-window>
        </template>
        <template v-else>
            <v-layout align-center justify-center class="py-10">
                <v-progress-circular indeterminate color="primary" size="24" />
            </v-layout>
        </template>
    </v-card>
    
    <upload-dialog ref="uploadDialog" @input="upload" />
</v-responsive>
</template>

<script>
import api from "@/api";

import UploadDialog from "@/components/console/gshop2/giftcards/upload-dialog.vue";
import GiftcardPins from "@/components/console/gshop2/giftcards/giftcard-pins.vue";
import vDateMonth from '@/components/plugins/vuetify/v-date-month.vue';

export default {
    components: {
        UploadDialog,
        GiftcardPins,
        vDateMonth
    },
    props: {
        value: { type: [Object, Array], default(){ return [] } },
        giftcard: { type: Object },
        thumb: { type: Object },
        images: { type: [Object, Array] },
    },
    data(){
        return {
            loading: false,

            options: this.$props.value || [],

            placeholders: [
                { name: "ex) ○○○ 상품권 1만원권", code: "ex) xm", faceamt: "ex) 10000",  value: "ex) 10000", notiStock: "ex) 10" },
                { name: "ex) ○○○ 상품권 3만원권", code: "ex) sm", faceamt: "ex) 30000",  value: "ex) 30000", notiStock: "ex) 10" },
                { name: "ex) ○○○ 상품권 5만원권", code: "ex) om", faceamt: "ex) 50000",  value: "ex) 50000", notiStock: "ex) 10" },
            ],

            platformItems: [
                { text: "excel", value: "excel" },
                { text: "nanuri", value: "nanuri" },
                { text: "nanuri-api", value: "nanuri-api" },
                { text: "nanuri-excel", value: "nanuri-excel" },
                { text: "nanuri-giftcard", value: "nanuri-giftcard" },
            ],
            
            filter: {
                menu: null,
                name: null,
                date: this.$dayjs().format("YYYY-MM"),
            },

            idle: { totalCount: 0 },
            issued: { totalCount: 0 },
            canceled: { totalCount: 0 },

            summaries: [],

            tabIndex: 0,

            siteInfo: {}
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search();
        },
        async search(status = ""){
            
            if(!this.giftcard._id) return;

            this.loading = true;

            let { summaries } = await api.console.gshop2.giftcards.pins.summaries.gets(this.giftcard._id);

            this.summaries = summaries;
            this.idle.totalCount = summaries.reduce((totalCount, summary) => totalCount + (summary.status == "미발행" ? summary.totalCount : 0), 0);

            var { summary } = await api.console.gshop2.giftcards.pins.gets(this.giftcard._id, {
                headers: { skip: 0, limit: 1 },
                params: { status: "발행완료", issuedAt: this.filter.date },
            });
            this.issued.totalCount = summary.totalCount;

            var { summary } = await api.console.gshop2.giftcards.pins.gets(this.giftcard._id, {
                headers: { skip: 0, limit: 1 },
                params: { status: "취소완료", canceledAt: this.filter.date },
            });
            this.canceled.totalCount = summary.totalCount;

            this.loading = false;
            this.$forceUpdate();
        },

        remove(items, item, index){
            item._id ? this.$set(item, "isDeleted", true) : items.splice(index, 1);
        },

        add(option){
            switch(option.platform){
                case "nanuri-giftcard": {
                    this.$refs.nanuriGiftcardDialog.$off();
                    this.$refs.nanuriGiftcardDialog.$on("select", (giftcard) => {
                        this.options.push({
                            name: giftcard.name,
                            code: giftcard._id,
                            type: "option",
                            salePrice: giftcard.faceamt || 0,
                            faceamt: giftcard.faceamt || 0,
                            ...option
                        });

                        this.giftcard.name = this.giftcard.name || giftcard.name;

                        api.getResource(giftcard.thumb).then(file => {
                            if(!this.giftcard.thumb) this.$emit("update:thumb", file);
                            if(!this.giftcard.images[0]) this.$emit("update:images", file);
                        });
                    });
                    this.$refs.nanuriGiftcardDialog.open();
                    break;
                }

                default: {
                    this.options.push({
                        name: null,
                        type: "option",
                        salePrice: 0,
                        faceamt: 0,
                        ...option
                    });
                }
            }
        },

        upload(pins = []){
            for(let { name, pno, faceamt, status = "미발행" } of pins){
                if(!this.options.find(option => option.name == name && !option.isDeleted)){
                    this.options.push({ 
                        name,
                        type: "option",
                        platform: "excel",
                        stock: 0,
                        salePrice: faceamt,
                        faceamt
                    });
                }
                
                this.pins.unshift({
                    name,
                    pno,
                    platform: "excel",
                    faceamt,
                    status,
                    createdAt: Date.now()
                });
            }
        }
    },
    computed: {
        // ...mapState(["siteInfo"]),
    },
    watch: {
        value(){
            this.options = this.value || [];
        },
        options: {
            deep: true,
            handler(){
                this.$emit("update:model-value", this.options);
                this.$emit("input", this.options)
            }
        },
        giftcard(){
            this.pins = this.giftcard.pins;
        }
    }
}
</script>

<style>
.shop-options .v-input.text-center input { text-align: center; }
</style>
