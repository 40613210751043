var render = function render(){
  var _vm$category, _vm$category$thumb, _vm$category$thumb2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "overflow-visible mx-auto",
    attrs: {
      "width": "100%",
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("카테고리 관리")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dense": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "white--text subtitle-2"
  }, [_vm._v("카테고리")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "accent",
      "fab": "",
      "dark": "",
      "small": "",
      "absolute": "",
      "bottom": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.categoryDialog.open();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', [_vm.categories.length ? _c('v-list', [_c('category-list', {
    attrs: {
      "categories": _vm.categories,
      "category": _vm.category
    },
    on: {
      "update:category": function (category) {
        return _vm.$data.category = category;
      }
    }
  })], 1) : _c('span', [_vm._v("등록된 카테고리가 없습니다."), _c('br'), _vm._v("카테고리를 등록해주세요")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "mx-1",
    attrs: {
      "disabled": !((_vm$category = _vm.category) !== null && _vm$category !== void 0 && _vm$category._id)
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("카테고리 기본정보")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "_id",
      "persistent-placeholder": "",
      "hide-details": "",
      "disabled": ""
    },
    model: {
      value: _vm.category._id,
      callback: function ($$v) {
        _vm.$set(_vm.category, "_id", $$v);
      },
      expression: "category._id"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "카테고리 이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.name,
      callback: function ($$v) {
        _vm.$set(_vm.category, "name", $$v);
      },
      expression: "category.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "카테고리 코드(영문)",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.code,
      callback: function ($$v) {
        _vm.$set(_vm.category, "code", $$v);
      },
      expression: "category.code"
    }
  })], 1), _c('v-card-text', [_c('div', {
    staticClass: "text-caption"
  }, [_vm._v("카테고리 썸네일")]), _c('v-layout', {
    attrs: {
      "no-gutters": "",
      "align-center": ""
    }
  }, [_vm.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.createObjectURL(_vm.thumb),
      "max-width": "96",
      "height": "auto",
      "contain": ""
    }
  }) : _vm.category.thumb ? _c('v-img', {
    attrs: {
      "src": (_vm$category$thumb = _vm.category.thumb) === null || _vm$category$thumb === void 0 ? void 0 : _vm$category$thumb.src,
      "max-width": "96",
      "height": "auto",
      "contain": ""
    }
  }) : _c('v-sheet', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "width": "96",
      "height": "96",
      "color": "grey-lighten-2"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-file-input', {
    attrs: {
      "placeholder": (_vm$category$thumb2 = _vm.category.thumb) === null || _vm$category$thumb2 === void 0 ? void 0 : _vm$category$thumb2.name
    },
    on: {
      "update:model-value": function (_ref) {
        var file = _ref[0];
        return _vm.thumb = file;
      }
    }
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-0 px-1"
  }, [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "grey darken-1"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("삭제")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1), _c('category-dialog', {
    ref: "categoryDialog",
    on: {
      "save": _vm.search
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }