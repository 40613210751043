var render = function render(){
  var _vm$giftcard$thumb, _vm$giftcard$thumb2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "overflow-visible mx-auto",
    attrs: {
      "width": "100%",
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.giftcard._id ? _c('span', [_vm._v("상품상세")]) : _c('span', [_vm._v("상품등록")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "d-flex pb-2 align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("카테고리")])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.giftcard._categories.push(null);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" 카테고리 추가")], 1)], 1), _c('v-card-text', [_c('v-select', {
    attrs: {
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "_id",
      "placeholder": "카테고리"
    },
    model: {
      value: _vm.giftcard._category,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "_category", $$v);
      },
      expression: "giftcard._category"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "d-flex pb-2 align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("브랜드")])])]), _c('v-card-text', [_c('v-layout', [_c('v-select', {
    attrs: {
      "items": _vm.brands,
      "item-text": "name",
      "item-value": "_id",
      "label": "브랜드"
    },
    model: {
      value: _vm.giftcard._brand,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "_brand", $$v);
      },
      expression: "giftcard._brand"
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("기본정보")])])]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "상품권이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.name,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "name", $$v);
      },
      expression: "giftcard.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "상품권코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.code,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "code", $$v);
      },
      expression: "giftcard.code"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "진열순서",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.no,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "no", $$v);
      },
      expression: "giftcard.no"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4",
    attrs: {
      "label": "액면가",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.faceamt,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "faceamt", $$v);
      },
      expression: "giftcard.faceamt"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "판매가",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.salePrice,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "salePrice", $$v);
      },
      expression: "giftcard.salePrice"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4",
    attrs: {
      "label": "재고",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.stock,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "stock", $$v);
      },
      expression: "giftcard.stock"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "발행업체",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.publisher,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "publisher", $$v);
      },
      expression: "giftcard.publisher"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "고객센터",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.center,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "center", $$v);
      },
      expression: "giftcard.center"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-layout', {
    attrs: {
      "no-gutters": "",
      "align-center": ""
    }
  }, [_vm.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.createObjectURL(_vm.thumb),
      "max-width": "96",
      "height": "auto",
      "contain": ""
    }
  }) : _vm.giftcard.thumb ? _c('v-img', {
    attrs: {
      "src": (_vm$giftcard$thumb = _vm.giftcard.thumb) === null || _vm$giftcard$thumb === void 0 ? void 0 : _vm$giftcard$thumb.src,
      "max-width": "96",
      "height": "auto",
      "contain": ""
    }
  }) : _c('v-sheet', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "width": "96",
      "height": "96",
      "color": "grey-lighten-2"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-file-input', {
    attrs: {
      "placeholder": (_vm$giftcard$thumb2 = _vm.giftcard.thumb) === null || _vm$giftcard$thumb2 === void 0 ? void 0 : _vm$giftcard$thumb2.name
    },
    on: {
      "change": function (file) {
        return _vm.thumb = file;
      }
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("이미지")])]), _c('v-spacer'), _c('span', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.giftcard.images.push(null);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("이미지추가")], 1)], 1)], 1), _c('v-card-text', _vm._l(_vm.giftcard.images, function (image, index) {
    var _vm$giftcard$images$i;
    return _c('v-layout', {
      key: index,
      staticClass: "mb-4",
      attrs: {
        "no-gutters": "",
        "align-center": ""
      }
    }, [_vm.images[index] ? _c('v-img', {
      attrs: {
        "src": _vm.createObjectURL(_vm.images[index]),
        "max-width": "96",
        "height": "auto",
        "contain": ""
      }
    }) : _vm.giftcard.images[index] ? _c('v-img', {
      attrs: {
        "src": _vm.giftcard.images[index].src,
        "max-width": "96",
        "height": "auto",
        "contain": ""
      }
    }) : _c('v-sheet', {
      staticClass: "d-flex justify-center align-center",
      attrs: {
        "width": "96",
        "height": "96",
        "color": "grey-lighten-2"
      }
    }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-file-input', {
      attrs: {
        "placeholder": (_vm$giftcard$images$i = _vm.giftcard.images[index]) === null || _vm$giftcard$images$i === void 0 ? void 0 : _vm$giftcard$images$i.name
      },
      on: {
        "change": function (file) {
          return _vm.$set(_vm.images, index, file);
        }
      }
    })], 1);
  }), 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("품절여부 (판매 / 품절)")])]), _c('v-spacer'), _c('span', [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "color": "red",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.soldout,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "soldout", $$v);
      },
      expression: "giftcard.soldout"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("진열여부 (진열안함 / 진열)")])]), _c('v-spacer'), _c('span', [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "color": "blue",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.onDisplay,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "onDisplay", $$v);
      },
      expression: "giftcard.onDisplay"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("추천여부 (추천안함 / 추천)")])]), _c('v-spacer'), _c('span', [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "color": "blue",
      "hide-details": ""
    },
    model: {
      value: _vm.giftcard.isRecommend,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "isRecommend", $$v);
      },
      expression: "giftcard.isRecommend"
    }
  })], 1)], 1)], 1), _vm.ready ? _c('giftcard-options', {
    staticClass: "mt-4",
    attrs: {
      "giftcard": _vm.giftcard
    },
    on: {
      "update:thumb": function (file) {
        return _vm.uploadThumb(file);
      },
      "update:images": function (file) {
        return _vm.uploadImage(file, 0);
      }
    },
    model: {
      value: _vm.giftcard.options,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "options", $$v);
      },
      expression: "giftcard.options"
    }
  }) : _vm._e(), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("상세정보")]), _c('v-card-text', [_vm.ready ? _c('naver-smarteditor', {
    model: {
      value: _vm.giftcard.content,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "content", $$v);
      },
      expression: "giftcard.content"
    }
  }) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("유의사항")]), _c('v-card-text', [_vm.ready ? _c('naver-smarteditor', {
    model: {
      value: _vm.giftcard.warning,
      callback: function ($$v) {
        _vm.$set(_vm.giftcard, "warning", $$v);
      },
      expression: "giftcard.warning"
    }
  }) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.ready
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }