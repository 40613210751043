<template>
    <div>
        <v-simple-table>
            <thead>
                <tr>
                    <th>상품권 이름</th>
                    <th>핀번호</th>
                    <th>액면가</th>
                    <th>상태</th>
                    <th>인증번호</th>
                    <th>발행일자</th>
                    <th>등록일자</th>
                    <th>발행일자</th>
                    <th>취소일자</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="pin, index in pins" :key="index">
                    <td>{{ pin.name }}</td>
                    <td>{{ pin.pno }}</td>
                    <td>{{ pin.faceamt.format() }}원</td>
                    <td>{{ pin.status }}</td>
                    <td>{{ pin?.certificationNo }}</td>
                    <td>{{ pin?.issueNo }}</td>
                    <td>{{ $dayjs(pin.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</td>
                    <td>{{ pin.issuedAt ? $dayjs(pin.issuedAt).format("YYYY-MM-DD HH:mm:ss") : "" }}</td>
                    <td>{{ pin.canceledAt ? $dayjs(pin.canceledAt).format("YYYY-MM-DD HH:mm:ss") : "" }}</td>
                </tr>
                <tr>
                </tr>
            </tbody>
            <v-divider />
        </v-simple-table>
        <v-responsive>
            <v-layout justify-center align-center class="my-2">
                <v-spacer />
                <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search()"/>
                <v-spacer />
                <!-- <div style="flex: 1;" class="d-flex justify-end align-center">
                    <span class="caption mr-4">Rows per page: </span>
                    <span>
                        <v-select v-model="limit" :items="[10, 15, 20, 50, 100]" density="compact" hide-details class="mt-n2 mr-2" @input="search()"/>
                    </span>
                </div> -->
            </v-layout>
        </v-responsive>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: {
        giftcard: { type: Object },
        filter: { type: Object, default: () => { return {}; } }
    },
    data(){
        return {
            loading: false,

            page: 1,
            pageCount: 0,
            limit: 10,

            pins: [],
            pinsHeaders: [
                {  value: "", align: "center", width: 200 },
                {  value: "", align: "center", width: 250 },
                {  value: "", align: "center", width: 150 },
                {  value: "", align: "center", width: 150 },
                {  value: "", align: "center", width: 200 },
                {  value: "", align: "center", width: 200, status: "발행완료" },
                {  value: "", align: "center", width: 200, status: "취소완료" },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search();
        },

        async search(){
            if(!this.giftcard._id) return;
            
            this.loading = true;
            this.pins = [];

            let { summary, pins } = await api.console.gshop.giftcards.pins.gets(this.giftcard._id, {
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter,
            });

            this.pins = pins;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.loading = false;

            this.$emit("search", summary);
        }
    },
    watch: {
        filter: {
            deep: true,
            handler(newFilter, oldFilter){
                if(newFilter.name != oldFilter.name || newFilter.issuedAt != oldFilter.issuedAt || newFilter.canceledAt != oldFilter.canceledAt){
                    this.page = 1;
                    this.search();
                }
            }
        }
    }
}
</script>