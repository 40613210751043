var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('draggable', {
    attrs: {
      "list": _vm.categories,
      "tag": "v-list"
    },
    on: {
      "change": function ($event) {
        return _vm.update();
      },
      "click": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
    }
  }, [_vm._l(_vm.categories, function (item) {
    var _item$children;
    return [!(item !== null && item !== void 0 && (_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length) ? [_c('v-list-item', {
      key: item._id,
      on: {
        "click": function ($event) {
          return _vm.$emit(`update:category`, item);
        }
      }
    }, [_vm._v(_vm._s(item.name))])] : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }