<template>
    <v-dialog v-model="show" max-width="800">
        <v-card>
            <v-card-title class="d-flex align-center">
                <span class="text-subtitle-1">엑셀 핀 업로드</span>
                <v-spacer />
                <v-btn color="green" variant="outlined" elevation="0" @click="download()">
                    <v-icon icon="mdi-microsoft-excel" class="mr-2"></v-icon>
                    <span>엑셀 양식 다운로드</span>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-dragdrop-field v-if="pins.length === 0" v-model="file" class="py-4" height="120" @input="upload" />
            <v-divider/>
            <v-table v-if="pins.length !== 0">
                <tbody>
                    <tr>
                        <th class="text-center">상품권이름</th>
                        <th class="text-center">핀번호</th>
                        <th class="text-center">상태</th>
                        <th class="text-center">액면가</th>
                        <th class="text-center">인증번호</th>
                        <th class="text-center">발행일자</th>
                    </tr>
                    <tr v-for="pin, index in pins" :key="index">
                        <td class="text-center">
                            {{ pin.name }}
                        </td>
                        <td class="text-center">
                            {{ pin.pno }}
                        </td>
                        <td class="text-center">
                            {{ pin.status }}
                        </td>
                        <td class="text-center">
                            {{ pin.faceamt.format() }}원
                        </td>
                        <td class="text-center">
                            {{ pin.certificationNo }}
                        </td>
                        <td class="text-center">
                            {{ pin.issueNo }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-divider />
            <v-card-actions>
                <span class="caption primary--text">
                    <b>* 액면가가 일치 하지 않는 항목은 스킵됩니다.</b>
                </span>
                <v-spacer/>
                <v-btn text color="grey" @click="close">취소</v-btn>
                <v-btn text color="primary" @click="confirm">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import XLSX from "@/plugins/xlsx";
import download from "downloadjs";
import VDragdropField from "@/components/plugins/vuetify/v-dragdrop-field.vue";
export default {
    components: {
        VDragdropField
    },
    props: {
        option: { type: Object, default: null },
        platform: { type: String, default: null }
    },
    data(){
        return {
            show: false,

            file: null,
            excelSheet: [],
            pinsHeaders: [
                { text: "상품권이름", value: "name", align: "center" },
                { text: "핀번호", value: "pno", align: "center" },
                { text: "상태", value: "status", align: "center" },
                { text: "액면가", value: "faceamt", align: "center" },
            ],
        }
    },
    methods: {
        download(){
            api.getResource("/data/example1.xls").then(file => download(file, "엑셀 양식.xls"))
        },
        open(){
            this.file = null;
            this.excelSheet = [];
            this.show = true;
        },
        close(){
            this.show = false;
        },
        confirm(){
            this.$emit("input", this.pins);
            this.close();
        },
        upload(file){
            console.log(file)
            if(!file) return;

            let reader = new FileReader();
            reader.onload = () => {
                let workSheets = [];
                let workBook = XLSX.read(reader.result, { type: "binary" });
                workBook.SheetNames.forEach(sheetName => {
                    let sheet = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                    workSheets.push(sheet);
                });

                this.excelSheet = workSheets[0];
            }
            reader.readAsBinaryString(file);
        },
    },
    computed: {
        pins(){
            let pins = [];
            for(let row of this.excelSheet){
                pins.push({
                    platform: this.platform || "excel",
                    name: row["상품권이름"],
                    pno: row["핀번호"],
                    status: row["상태"],
                    faceamt: +row["액면가"] || 0,
                    certificationNo: row["인증번호"] || null,
                    issueNo: row["발행일자"] || null,
                })
            }
            return pins;
        }
    }
}
</script>