var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', _vm._l(_vm.max, function (depth, index) {
    return _c('v-flex', {
      key: index,
      attrs: {
        "grow": ""
      }
    }, [_c('v-select', {
      staticClass: "mt-0",
      attrs: {
        "items": _vm.items(depth),
        "item-title": "name",
        "item-value": "_id",
        "model-value": _vm.values[depth - 1],
        "label": `${depth}차 카테고리`,
        "persistent-placeholder": _vm.$props['persistent-placeholder'],
        "dense": _vm.dense,
        "hide-details": _vm.hideDetails
      },
      on: {
        "update:model-value": _vm.input
      }
    })], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }