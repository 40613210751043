<template>
    <draggable :list="categories" tag="v-list" @change="update()" @click.prevent.stop>
        <template v-for="item in categories">
            <template v-if="!item?.children?.length">
                <v-list-item @click="$emit(`update:category`, item)" :key="item._id">{{ item.name }}</v-list-item>
            </template>
        </template>
    </draggable>
</template>

<script>
export default {
    props: {
        categories: { type: Array, default: [] },
        category: { type: Object, default: null },
    },
    methods: {
        update(){
            this.$emit(`update:categories`, this.categories);
            this.$emit(`input`);
        },
        click(item){
            console.log({ item });
            this.$emit(`click`, item);
        }
    }
}
</script>