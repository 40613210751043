<template>
    <v-responsive width="100%" max-width="720" class="overflow-visible mx-auto">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline text-start">브랜드 관리</v-col>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>
        
        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-2 mx-1">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.searchKey" :items="searchKeys" item-title="text" item-value="value" label="검색조건" placeholder=" " hide-details @input="filter.searchValue = null;" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field v-model="filter.searchValue" label="검색어" placeholder=" " clearable hide-details :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="text" elevation="0" rounded text @click="page = 1; search(true)">
                        <v-icon  class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>

        <v-simple-table class="elevation-1 mt-4">
            <thead>
                <tr>
                    <th class="text-center" style="width: 100px">순서</th>
                    <th class="text-center" style="width: 100px">썸네일</th>
                    <th>브랜드</th>
                    <th class="text-center" style="width: 100px">진열해제<br/> / 진열</th>
                    <th class="text-center" style="width: 100px">카테고리</th>
                    <th class="text-center" style="width: 100px">actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="brand, index in brands" :key="index">
                    <td class="text-center">{{ brand.no }}</td>
                    <td class="text-center"><v-img :src="brand?.thumb?.src" max-width="56" height="56" contain class="ma-auto"/></td>
                    <td>{{ brand.name }}</td>
                    <td class="text-center">
                        <v-switch v-model="brand.onDisplay" density="compact" color="blue" hide-details class="d-inline-block mt-0" @change="update({ _id: brand._id, onDisplay: brand.onDisplay })"/>
                    </td>
                    <td class="text-center">{{ brand.category?.name }}</td>
                    <td class="text-center">
                        <v-icon size="20" class="mx-1" @click="edit(brand)">mdi-pencil</v-icon>
                        <v-icon size="20" class="mx-1" @click="remove(brand)">mdi-delete</v-icon>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <v-btn color="accent" size="large" icon="mdi-pencil" elevation="1" fab fixed right bottom @click="create()"><v-icon>mdi-pencil</v-icon></v-btn>

    </v-responsive>
</template>

<script>
import api from "@/api";
import XLSX from "@/plugins/xlsx";

export default {
    data(){
        return {
            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,

                type: "brand"
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            brands: [],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "브랜드", value: "name" },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, brands } = await api.console.gshop2.brands.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.brands = brands;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        create(){
            this.$router.push(`/dhbizadm/gshop2/brands/create`);
        },
        edit(brand){
            this.$router.push(`/dhbizadm/gshop2/brands/${brand._id}`);
        },
        async update(brand){
            await api.console.gshop2.brands.put(brand);
        },
        async remove(brand){
            if(confirm("삭제하시겠습니까?")){
                await api.console.gshop2.brands.delete(brand);
                alert("삭제되었습니다");
                this.search(false);
            }
        },
        async excel(){
            let { brands } = await api.console.gshop2.brands.gets({ headers: { limit: 0 }, params: this.filter });

            let rows = [];
            brands.forEach(brand => {
                rows.push({
                    "진열순서": brand.sequence,
                    "상품코드": brand.code,
                    "상품이름": brand.name,
                    "등록일자": this.$dayjs(brand.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "상품목록");
            XLSX.writeFile(workbook, "상품목록.xlsx");
        }
    }
}
</script>