<template>
    <v-responsive v-if="ready" max-width="720" class="overflow-visible mx-auto">

        <v-row class="mt-0 mt-md-4 px-1" align="center">
            <v-col cols="auto" class="headline text-start">
                <span v-if="brand._id">브랜드상세</span>
                <span v-else>브랜드등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mt-4">
            <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
            <v-divider />
            <v-card-text>
                <v-select v-model="brand._category" :items="categories" item-text="name" item-value="_id" label="커테고리" persistent-placeholder hide-details />
                <v-text-field v-model="brand.name" label="브랜드 명칭" persistent-placeholder hide-details class="mt-4"/>
                <v-text-field v-model="brand.no" label="브랜드 순서" persistent-placeholder hide-details class="mt-4"/>
            </v-card-text>
            <v-divider/>
            <v-card-text>
                <v-layout no-gutters align-center>
                    <v-img v-if="thumb" :src="createObjectURL(thumb)" max-width="56" height="auto" contain></v-img>
                    <v-img v-else-if="brand.thumb" :src="brand.thumb?.src" max-width="56" height="auto" contain></v-img>
                    <v-sheet v-else width="56" height="56" color="grey-lighten-2" class="d-flex justify-center align-center "><v-icon>mdi-image</v-icon></v-sheet>
                    <v-file-input :placeholder="brand.thumb?.name" label="썸네일" persistent-placeholder @change="(file) => thumb = file" />
                </v-layout>
            </v-card-text>
        </v-card>

        <v-card class=" mt-4">
            <v-card-title class="d-flex align-center">
                <span class="subtitle-2"><b>진열여부 (진열안함 / 진열)</b></span>
                <v-spacer/>
                <span>
                    <v-switch v-model="brand.onDisplay" color="blue" hide-details class="mt-0"/>
                </span>
            </v-card-title>
            <v-divider/>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save()">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

        <daum-postcode ref="daumPostcode" @input="({ postcode, address }) => { brand.postcode = postcode; brand.address1 = address; }" />

    </v-responsive>
</template>

<script>
import api from "@/api";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import CategorySelect from "@/components/console/gshop2/categories/category-select.vue";
// import PurchaseList from "@/components/console/user/purchase-list.vue";

export default {
    components: {
        NaverSmarteditor,
        VPasswordField,
        DaumPostcode,
        CategorySelect
        // PurchaseList
    },
    data(){
        return {
            ready: false,

            brand: {
                _id: this.$route.params._id
            },

            thumb: null
        }
    },
    methods: {
        async init(){
            if(this.brand._id){
                let { brand } = await api.console.gshop2.brands.get(this.brand);
                this.brand = brand;
            }

            let { categories } = await api.console.gshop2.categories.gets();
            this.categories = categories;

            this.ready = true;
        },
        async save(){
            let { brand } = await api.console.gshop2.brands?.[this.brand._id ? `put` : `post`](this.brand);

            if(this.thumb){
                brand.thumb = await api.console.gshop2.brands.thumb.post(brand._id, this.thumb);
                this.thumb = null;
            }

            await api.console.gshop2.brands.put(brand);

            alert("저장되었습니다");
            this.$router.go(-1);
        },
        createObjectURL(url){
            try{
                return URL.createObjectURL(url)
            }
            catch(error){}
            return null;
        }
    },
    mounted(){
        this.init();
    },
}
</script>